import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import pack from "../../../package.json";
import * as authActions from "../auth/actions";
import AppContainer from "../common/AppContainer";
import * as navActions from "../common/actions";
import Submit from "../common/Submit";
import TextField from "../common/TextField";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";

const styles = (theme) => ({
    marginBottom: {
        marginBottom: 40
    },
    marginTop: {
        marginTop: 30,
    },
    leftDivide: {
        paddingRight: 32,
        borderRight: "2px solid #666666",
        [theme.breakpoints.down("sm")]: {
            display: "none"
        }
    },
    rightDivide: {
        paddingLeft: 32,
        [theme.breakpoints.down("sm")]: {
            display: "none"
        }
    },
    middle: {
        [theme.breakpoints.up("md")]: {
            display: "none"
        }
    },
    introText: {
        marginBottom: 80,
        marginTop: 40,
        fontSize: "1.95rem",
        fontFamily: "Roboto, 'Helvetica Neue', Arial, sans-serif",
        fontWeight: 300,
        lineHeight: 1.17,
        [theme.breakpoints.down("sm")]: {
            marginTop: 10,
            marginBottom: 10
        }
    },
    loginError: {
        color: "red",
        textAlign: "center"
    },
    marginBottomButton: {
        marginBottom: 24
    },
    imageSide: {
        width: "100%",
        cursor: "pointer"
    },
    forgot: {
        float: "right",
        marginTop: 8
    },
    h: {
        fontSize: "1.55rem",
        fontFamily: "Roboto, 'Helvetica Neue', Arial, sans-serif",
        fontWeight: 300,
        lineHeight: 1.17,
    },
    logo: {
        maxWidth: 300,
        [theme.breakpoints.down("sm")]: {
            maxWidth: 150
        }
    },
    version: {
        position: "absolute",
        bottom: "0%",
        right: "42%"
    }
});

class HomePage extends Component {
    constructor(props){
        super(props);
        
        var subdomain = "dev";
	    var logoPath = "dev";
        if(window.location.hostname.split('.').length === 3){
            subdomain = window.location.hostname.split('.')[0];
            logoPath = subdomain;
        } 

        this.state = {
            subdomain,
            logoPath
        }
    }

    continueRegister = () => {
        this.props.history.push("/register");
    };

    logOut = () => {
        this.props.authLogout();
    };

    continueDashboard = () => {
        this.props.navSelectPage("map");
        this.props.history.push("/map");
    };

    errorValidator = values => {
        const validateUsername = value => {
            return !value ? "Username is required." : null;
        };
        const validatePassword = value => {
            return !value ? "Password is required." : null;
        };
        return {
            username: validateUsername(values.username),
            password: validatePassword(values.password)
        };
    };

    loginSubmit = (values, e, formApi) => {
        this.props.authLogin(values).then(() => {
            if (this.props.authState.error) {
                formApi.setValue("password", "");
            } else if (this.props.authState.user) {
                if (this.props.authState.user.redirect) {
                    this.props.history.push(this.props.authState.user.redirect.replace("#", "") + "?old=True");
                } else {
                    this.continueDashboard();
                }
            }
        });
    };

    render() {
        const { classes, authState } = this.props;
        const { logoPath, subdomain } = this.state;
        if (!authState) return "...";

        return (
            <AppContainer>
                <Grid container justify="center">
                    <Grid item xs={11} md={8} lg={6} xl={4}>
                        <Typography className={classes.introText} variant="h1" >
                            Drainage Management Portal
                        </Typography>
                        <Grid container className={classes.marginTop}>
                            <Grid item xs={6} className={classes.leftDivide}>
                                <img 
                                    className={classes.logo}
                                    src={`https://${subdomain}.drainagedb.net/data/${logoPath}/logo.png`} 
                                    alt={`DrainageDB ${subdomain} Logo`}
                                />
                                <Typography style={{marginTop: 10}} className={classes.h} variant="h2" gutterBottom>
                                    Need Help?
                                </Typography>
                                <Typography variant="body1" className={classes.marginBottom}>
                                    Please contact out technical support at
                                    &nbsp;
                                    <a 
                                        style={{wordBreak: "break-word"}}
                                        aria-label="Need Help? Please contact us at D r a i n a g e D B @ h o u s t o n e n g . c o m" 
                                        href="mailto:DrainageDB@houstoneng.com">
                                        DrainageDB@houstoneng.com
                                    </a>
                                    &nbsp;
                                    or call 763-493-4522
                                </Typography>
                                <Typography variant="body1" className={classes.marginBottom}>
                                    {"Version " + pack.version}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} className={classes.rightDivide}>
                                {authState.user && (
                                    <div>
                                        <Typography className={classes.h} variant="h2" gutterBottom>
                                            Welcome, {authState.user.name}
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            onClick={this.continueDashboard}
                                            color="primary"
                                            fullWidth
                                            className={classes.marginBottomButton}>
                                            Continue to Application
                                        </Button>
                                        <Button 
                                            variant="contained"
                                            onClick={this.logOut} 
                                            fullWidth>
                                            Logout
                                        </Button>
                                    </div>
                                )}
                                {!authState.user && (
                                    <Form
                                        dontValidateOnMount="true"
                                        validateOnSubmit="true"
                                        validateError={this.errorValidator}
                                        onSubmit={this.loginSubmit}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <Typography className={classes.h} variant="h2" gutterBottom>
                                                    Existing Users
                                                </Typography>
                                                <TextField field="username" label="Username" fullWidth />
                                                <TextField
                                                    field="password"
                                                    label="Password"
                                                    type="password"
                                                    fullWidth
                                                />
                                                <Typography className={classes.loginError} gutterBottom>
                                                    {authState.error}
                                                </Typography>
                                                <Submit label="Login" gridSize={24} loading={authState.pending} />
                                                <Button
                                                    variant="contained"
                                                    component={Link}
                                                    to="/password/reset"
                                                    className={classes.forgot}>
                                                    Forgot your password?
                                                </Button>
                                            </form>
                                        )}
                                    </Form>
                                )}
                            </Grid>
                            <Grid item xs={12} className={classes.middle}>
                                {authState.user && (
                                    <div>
                                        <Typography className={classes.h} variant="h2" gutterBottom>
                                            Welcome, {authState.user.name}
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            onClick={this.continueDashboard}
                                            color="primary"
                                            fullWidth
                                            className={classes.marginBottomButton}>
                                            Continue to Application
                                        </Button>
                                        <Button 
                                            variant="contained"
                                            onClick={this.logOut} 
                                            fullWidth>
                                            Logout
                                        </Button>
                                    </div>
                                )}
                                {!authState.user && (
                                    <Form
                                        dontValidateOnMount="true"
                                        validateOnSubmit="true"
                                        validateError={this.errorValidator}
                                        onSubmit={this.loginSubmit}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <Typography className={classes.h} variant="h2" gutterBottom>
                                                    Existing Users
                                                </Typography>
                                                <TextField field="username" label="Username" fullWidth />
                                                <TextField
                                                    field="password"
                                                    label="Password"
                                                    type="password"
                                                    fullWidth
                                                />
                                                <Typography className={classes.loginError} gutterBottom>
                                                    {authState.error}
                                                </Typography>
                                                <Submit label="Login" gridSize={24} loading={authState.pending} />
                                                <Button
                                                    variant="contained"
                                                    component={Link}
                                                    to="/password/reset"
                                                    className={classes.forgot}>
                                                    Forgot your password?
                                                </Button>
                                            </form>
                                        )}
                                    </Form>
                                )}
                                <Typography variant="body1" className={classes.version}>
                                    {"Version " + pack.version}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

HomePage = connect(
    state => ({ authState: state.auth }),
    {
        ...authActions,
        ...navActions
    }
)(HomePage);

export default withStyles(styles)(withRouter(HomePage));
