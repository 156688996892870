import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withStyles, Grid, Tooltip } from "@material-ui/core";
import { Form } from "react-form";
import { connect } from "react-redux";
import { addToForm, addtoFormFile, formateDate, formatMoney, formatPercent, formatNumber } from "../../api/utils";
import { getFormattedDate } from "../common/CommonFunction";
import PageHeader from "../common/PageHeader";
import Checkbox from "../common/Checkbox";
import FileFormInput from "../common/FileFormInput";
import CommonTable from "../admin/Table";
import DatePicker from "../common/DatePicker";
import HelpLabel from "../common/HelpLabel";
import TextField from "../common/TextField";
import Select from "../common/Select";
import MultiSelect from "../common/MultiSelect";
import WarningDialog from "../common/WarningDialog";
import RadioGroup from "../common/RadioGroup";
import Subquestion from "../common/Subquestion";
import { MAKE_OPTIONS, MAKE_OPTIONS_KEY } from "../../api/constants";
import { DrainageSystem as SystemType, InpsectionType, LegalType, Status, Units } from "../common/SelectOptions";
import Divider from "@material-ui/core/Divider";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Close from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import SaveIcon from "@material-ui/icons/Save";
import ClearIcon from "@material-ui/icons/Clear";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { createSelector } from "../common/orm";
import {
    Local_Twpshipxy,
    Local_commish,
    DrainageAnnual,
    Drainagenote,
    DrainageSystem,
    JointSystem,
} from "../mypage/models";
import RecordTable from "../common/RecordTable";

const getCommish = Local_commish.selectAll();
const getTown = Local_Twpshipxy.selectAll();

const getDrainage = createSelector(
    (state, ownProps) => ownProps.system,
    (session, system) => {
        return session.DrainageSystem.filter((sys) => sys.id === system.id).toRefArray()[0];
    }
);

const getJointSystem = createSelector(
    (state, ownProps) => ownProps.system,
    (session, system) => {
        return session.JointSystem.filter((sys) => sys.drainage === system.id)
            .toModelArray()
            .map((x) => {
                return {
                    ...x.ref,
                    table_label: x.county && x.county.county ? x.county.county : "",
                    benefit_amt_label: formatMoney(x.benefit_amt),
                    percentage_label: formatPercent(x.percentage),
                };
            });
    }
);

const getNotes = createSelector(
    (state, ownProps) => ownProps.system,
    (session, system) => {
        return session.Drainagenote.filter((note) => note.drainage === system.id)
            .orderBy("currentdate")
            .toRefArray();
    }
);

const getDrainageAnnuals = createSelector(
    (state, ownProps) => ownProps.system,
    (session, system) => {
        return session.DrainageAnnual.filter((annual) => annual.drainage_id === system.id).toRefArray();
    }
);

const getMapSetting = createSelector((schema) => {
    return schema.MapSetting.first();
});

const jointColumnData = [
    { id: "actions", numeric: false, label: "", allowSort: false },
    { id: "table_label", numeric: false, label: "County", allowSort: true },
    { id: "benefit_amt_label", numeric: true, label: "Benefit Amount", allowSort: true },
    { id: "percentage_label", numeric: true, label: "Percentage", allowSort: true },
    { id: "notes", numeric: true, label: "Notes", allowSort: true },
];

const noteColumnData = [
    { id: "actions", numeric: false, label: "", allowSort: false },
    { id: "currentdate", numeric: false, label: "Date", allowSort: true },
    { id: "person", numeric: false, label: "Person", allowSort: true },
    { id: "followup", numeric: false, label: "Follow-Up", allowSort: true },
    { id: "notes", numeric: false, label: "Notes", allowSort: true },
];

const AnnualcolumnData = [
    { id: "actions", numeric: false, label: "", allowSort: false },
    { id: "year", numeric: false, label: "Year", allowSort: false },
    { id: "inspecttype", numeric: false, label: "Type", allowSort: false },
    { id: "notes", numeric: false, label: "Notes", allowSort: false },
    { id: "annualreport", numeric: false, label: "Attachment", allowSort: false, openWindow: true },
];
const AnnualkeyData = AnnualcolumnData.slice(1);

const styles = (theme) => ({
    mb: {
        marginBottom: 10,
    },
    mt: {
        marginTop: 10,
    },
    summary: {
        backgroundColor: "rgb(221, 221, 221)",
    },
    flex: {
        flex: 1,
    },
    width: {
        width: 100,
        marginRight: 3,
        marginBottom: 10,
        backgroundColor: "#82d4f3",
        color: "white",
    },
    content: {
        paddingBottom: "16px !important",
    },
    submitWrapper: {
        position: "relative",
    },
    progress: {
        marginLeft: 5,
        color: "#4CAF50",
    },
    header: {
        marginTop: 20,
    },
    margin: {
        marginBottom: 10,
    },
    summaryView: {
        display: "flex",
        flexGrow: 1,
        padding: 8,
        alignItems: "center",
        justifyContent: "space-between",
    },
    container: {
        width: "100%",
        border: "1px solid black",
        borderRadius: 6,
        marginLeft: 6,
        marginBottom: 15,
        marginTop: 5,
    },
});

const number_list = [
    "annual_authorization",
    "totallength",
    "openlength",
    "tilelength",
    "milesbuffer",
    "benefitacres",
    "origbenefitacres",
    "redeterbenefitacres",
    "outletperc",
    "roadacres",
    "constructease",
    "origbenefitdollars",
    "redeterbenefitdollars",
    "benefitpurpose",
];

class Description extends Component {
    state = {
        activeInspection: {},
        activeJoint: {},
        activeYear: {},
        yearOpen: false,
        jointOpen: false,
        noteOpen: false,
        annualOpen: false,
        annualID: null,
        jointID: null,
        noteID: null,
        file: null,
        confirmUpload: false,
        loading: false,
        calculated: "",
    };
    Formref = React.createRef();

    componentWillUnmount = () => {
        const { ormDrainageSystemUpdateLocalOnly } = this.props;

        const values = this.Formref.current.store.getState().values;

        if (values["acctfundbalance"] === "") {
            values["acctfundbalance"] = "0";
        }

        number_list.forEach((x) => {
            if (values[x] !== null && values[x] === "") {
                values[x] = null;
            }
        });

        ormDrainageSystemUpdateLocalOnly({
            ...values,
        });
    };

    saveDrainge = (values) => {
        const { authState, ormDrainageSystemUpdatePromise, saveBad, saveGood } = this.props;

        values["modified_user"] = authState.user.username;
        values["modified_date"] = getFormattedDate();

        if (values["acctfundbalance"] === null || values["acctfundbalance"] === "") {
            values["acctfundbalance"] = "0";
        }

        number_list.forEach((x) => {
            if (values[x] !== null && values[x] === "") {
                values[x] = null;
            }
        });

        ormDrainageSystemUpdatePromise({
            ...values,
        }).then((id) => {
            if (!id) {
                saveBad();
            } else {
                saveGood();
            }
        });
    };

    annualOnClickEdit = (e, l) => {
        this.setState({ activeInspection: l, annualOpen: true, edit: true });
    };

    annualsubmitForm = (e) => {
        const {
            ormDrainageAnnualCreateForm,
            ormDrainageAnnualUpdateForm,
            ormDrainageAnnualUpdatePromise,
            updateSystem,
            system,
            saveBad,
            saveGood,
        } = this.props;
        const { file } = this.state;
        if (!e.id || file) {
            var form_data = new FormData();
            if (e.id) {
                form_data.append("id", e.id);
            }
            form_data.append("drainage_id", system.id);
            form_data.append("inspecttype", e.inspecttype);
            form_data.append("year", e.year);
            addToForm(form_data, "notes", e.notes);
            addtoFormFile(form_data, "annualreport", file);

            this.setState({ loading: true });
            const postForm = e.id ? ormDrainageAnnualUpdateForm : ormDrainageAnnualCreateForm;
            postForm(form_data).then((id) => {
                this.setState({ annualOpen: false, file: null });
                if (!id) {
                    saveBad();
                } else {
                    saveGood();
                }
                this.setState({ loading: false });
            });
        } else {
            delete e.annualreport;
            ormDrainageAnnualUpdatePromise({
                drainage_id: system.id,
                ...e,
            }).then((id) => {
                this.setState({ annualOpen: false });
                if (!id) {
                    saveBad();
                } else {
                    saveGood();
                }
            });
        }
        updateSystem();
    };

    errorValidator = (values) => {
        const validateInspectionType = (inspecttype) => {
            if (!inspecttype) {
                return "Required";
            }
            return null;
        };
        return {
            inspecttype: validateInspectionType(values.inspecttype),
        };
    };

    calcLength = (e, formApi) => {
        var length = formApi.getValue("lengthtype");

        if (length !== e) {
            var tile = formApi.getValue("tilelength");
            var open = formApi.getValue("openlength");

            if (e === "Feet") {
                tile = Math.round(tile * 5280);
                open = Math.round(open * 5280);
            } else {
                tile = Number(tile / 5280);
                open = Number(open / 5280);
            }

            var total = parseFloat(tile + open).toFixed(4);

            formApi.setValue("tilelength", tile);
            formApi.setValue("openlength", open);
            formApi.setValue("totallength", total);
        }
    };

    totalUpdate = (e, isOpen, formApi) => {
        var temp;
        if (isOpen) {
            temp = formApi.getValue("openlength");
        } else {
            temp = formApi.getValue("tilelength");
        }

        var total = Number(temp) + Number(e);
        formApi.setValue("totallength", total);
    };

    handleFiles = (field, val, formApi) => {
        const { fileSize } = this.props;

        this.setState({ file: val });

        if (!val) {
            this.setState({ confirmUpload: false, calculated: "0 Minutes" });
            formApi.setValue("lock_submit", false);
            return;
        }

        const size = val.size / 1000000;
        if (size > 250 && fileSize) {
            formApi.setValue(field, null);
            formApi.setValue("lock_submit", false);
            this.setState({ file: null, confirmUpload: false });
            fileSize();
        } else if (size > 100) {
            const calculated = Math.round(size / 40);
            this.setState({ confirmUpload: true, calculated: `${calculated} Minutes` });
        } else {
            //if new file is selected and less than 100 MB hide and reset lock
            this.setState({ confirmUpload: false, calculated: "0 Minutes" });
            formApi.setValue("lock_submit", false);
        }
    };

    clearTownship = (formApi) => {
        formApi.setValue("township", null);
    };

    clearDistrict = (formApi) => {
        formApi.setValue("district", null);
    };

    addJoint = (values) => {
        const { ormJointSystemCreate, ormJointSystemUpdatePromise, updateSystem, system, saveGood, saveBad } =
            this.props;

        if (values.percentage !== null && values.percentage === "") {
            values.percentage = "0.00";
        }

        if (values.id) {
            ormJointSystemUpdatePromise({ ...values }).then((id) => {
                this.setState({ jointOpen: false, activeJoint: null });
                if (!id) {
                    saveBad();
                } else {
                    saveGood();
                }
            });
        } else {
            ormJointSystemCreate({
                ...values,
                drainage_id: system.id,
                county_id: values.county,
            }).then((id) => {
                this.setState({ jointOpen: false, activeJoint: null });
                if (!id) {
                    saveBad();
                } else {
                    saveGood();
                }
            });
        }
        updateSystem();
    };

    jointErrorValidator = (values) => {
        const { jointSystems } = this.props;
        const id = values.id;

        const isEmpty = (value) => {
            if (!value || value === "") {
                return "Required";
            }
            return null;
        };

        var total = 0.0;

        jointSystems.forEach((x) => {
            if (x.id !== id) {
                total += parseFloat(x.percentage);
            }
        });

        total += parseFloat(values.percentage);

        return {
            county: isEmpty(values.county),
            percentage:
                total > 100.0
                    ? `Sum of all percentages must be less than or equal to 100 percent! Total Percentage (${total}%)`
                    : null,
        };
    };

    saveNote = (values) => {
        const { authState, ormDrainagenoteCreate, ormDrainageSystemUpdatePromise, system, saveBad, saveGood } =
            this.props;
        values["followup"] = values["followup"] ? "True" : "False";
        ormDrainagenoteCreate({
            drainage_id: system.id,
            ...values,
        }).then((id) => {
            if (!id) {
                saveBad();
            } else {
                saveGood();
            }
            this.setState({ noteOpen: false });
        });
        ormDrainageSystemUpdatePromise({
            id: system.id,
            authState: authState.user.username,
            modified_date: getFormattedDate(),
        }).then((id) => {
            if (!id) {
                saveBad();
            } else {
                saveGood();
            }
        });
    };

    defaultValues = () => {
        const { authState } = this.props;
        var defaultValues = {};
        defaultValues["person"] = authState.user.username;
        defaultValues["followup"] = false;
        return defaultValues;
    };

    summaryPanel = ({ classes, child, title }) => (
        <div className={classes.summaryView}>
            <div>
                <Typography>{title}</Typography>
            </div>
            <div style={{ display: "inline-flex" }}>{child}</div>
        </div>
    );

    render() {
        const {
            authState,
            annuals,
            classes,
            commish,
            towns,
            system,
            notes,
            jointSystems,
            jointOptions,
            ormDrainageAnnualDeletePromise,
            ormJointSystemDeletePromise,
            ormDrainagenoteDeletePromise,
            updateSystem,
            saveGood,
            settings,
            saveBad,
        } = this.props;
        const {
            activeInspection,
            activeJoint,
            activeYear,
            annualOpen,
            noteOpen,
            yearOpen,
            jointOpen,
            edit,
            annualDelete,
            jointDelete,
            noteDelete,
            annualID,
            jointID,
            noteID,
            confirmUpload,
            loading,
            calculated,
        } = this.state;

        const viewOnly = authState.user.role === "View Only";

        var tempCounts = [];
        if (system.counts) {
            tempCounts = system.counts.split(",");
        }

        return (
            <div>
                <PageHeader
                    topSpace={true}
                    bottomSpace={true}
                    title={"Drainage System : "}
                    drainage={system.drainage}
                />
                <Grid container spacing={3} className={classes.container}>
                    <Grid item xs={12}>
                        <div className={classes.view}>
                            <div>
                                <Typography variant="h4">Summary Information</Typography>
                            </div>
                            <div></div>
                        </div>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Typography>
                            <b>Open Length({system.lengthtype}):</b>
                        </Typography>
                        <Typography>{formatNumber(system.openlength)}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Typography>
                            <b>Tile Length({system.lengthtype}):</b>
                        </Typography>
                        <Typography>{formatNumber(system.tilelength)}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Typography>
                            <b>Redetermination Date:</b>
                        </Typography>
                        <Typography>{formateDate(system.rob)}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Typography>
                            <b>Most Recent Benefit Acres:</b>
                        </Typography>
                        <Typography>{formatNumber(system.redeterbenefitacres)}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Typography>
                            <b>Most Recent Benefit Amt:</b>
                        </Typography>
                        <Typography>{formatMoney(system.redeterbenefitdollars)}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Typography>
                            <b>Outlet %:</b>
                        </Typography>
                        <Typography>{formatPercent(system.outletperc)}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Typography>
                            <b>Benefit Amt for Assmt Purposes Only:</b>
                        </Typography>
                        <Typography>{formatMoney(system.benefitpurpose)}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Typography>
                            <b>Commissioner Districts</b>
                        </Typography>
                        <Typography>{system.district}</Typography>
                    </Grid>
                </Grid>
                <Form
                    ref={this.Formref}
                    getApi={(el) => (this.formApi = el)}
                    defaultValues={system}
                    onSubmit={(values) => this.saveDrainge(values)}>
                    {(formApi) => (
                        <form onSubmit={formApi.submitForm}>
                            <Grid container direction="row" justify="flex-end" alignItems="flex-start">
                                <Grid item xs={2}>
                                    <Button
                                        disabled={loading}
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        style={{ float: "right", display: viewOnly && "none " }}>
                                        <SaveIcon />
                                        &nbsp;&nbsp;Save
                                    </Button>
                                </Grid>
                            </Grid>
                            <ExpansionPanel style={{ marginTop: "16px" }}>
                                <ExpansionPanelSummary
                                    className={classes.summary}
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header">
                                    <this.summaryPanel classes={classes} title="General Information" />
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <TextField
                                                field="fullsystemname"
                                                label="Full Drainage System Name"
                                                fullWidth
                                                disabled={viewOnly}></TextField>
                                            <Select
                                                disabled={viewOnly}
                                                options={Status}
                                                field="status"
                                                label={
                                                    <HelpLabel
                                                        inputLabel="Status"
                                                        helpText={
                                                            <div>
                                                                Active is defined as system is current and legally
                                                                established. Dismissed is a system that was never
                                                                legally established or built.
                                                            </div>
                                                        }
                                                    />
                                                }
                                                fullWidth
                                            />
                                            <Select
                                                options={LegalType}
                                                field="jurisdiction"
                                                label="Legal Type"
                                                fullWidth
                                                disabled={viewOnly}
                                            />
                                            <TextField field="repairdates" fullWidth disabled={viewOnly}>
                                                <HelpLabel
                                                    inputLabel="Full System Repair Dates"
                                                    helpText={
                                                        <div>
                                                            List any dates that the system has had a full repair
                                                            completed. Comma seperate the dates.
                                                        </div>
                                                    }
                                                />
                                            </TextField>
                                            <Checkbox
                                                field="recorded"
                                                label="Has the Ditch Right of Way been Recorded?"
                                                disabled={viewOnly}
                                            />
                                            <Checkbox
                                                field="paid_ease"
                                                label="Has paid Contruction Easement?"
                                                disabled={viewOnly}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                field="transferto"
                                                label="If Transferred, to Whom"
                                                fullWidth
                                                disabled={viewOnly}
                                            />
                                            <Select
                                                options={SystemType}
                                                field="ditchtype"
                                                label="Drainage System Type"
                                                fullWidth
                                                disabled={viewOnly}
                                            />
                                            <TextField
                                                field="year"
                                                label="Year Established"
                                                fullWidth
                                                disabled={viewOnly}
                                            />
                                            <TextField field="improvedates" fullWidth disabled={viewOnly}>
                                                <HelpLabel
                                                    inputLabel="Improvement Dates"
                                                    helpText={
                                                        <div>
                                                            List any dates that the system had improvement orders. Comma
                                                            seperate the dates
                                                        </div>
                                                    }
                                                />
                                            </TextField>
                                            <TextField
                                                field="milesbuffer"
                                                label="If so, Miles of Buffer"
                                                scale={3}
                                                useDecimalFormat
                                                fullWidth
                                                disabled={viewOnly}></TextField>
                                            <Checkbox
                                                field="bufferreq"
                                                label="Is there a buffer strip required on this system?"
                                                disabled={viewOnly}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                field="openlength"
                                                label="Open Length"
                                                useDecimalFormat
                                                fullWidth
                                                disabled={viewOnly}
                                                eventHandle={(e) => {
                                                    this.totalUpdate(e, false, formApi);
                                                }}
                                            />
                                            <Select
                                                options={Units}
                                                field="lengthtype"
                                                label="Length Units"
                                                fullWidth
                                                disabled={viewOnly}
                                                eventHandle={(e) => {
                                                    this.calcLength(e, formApi);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                field="tilelength"
                                                label="Tile Length"
                                                useDecimalFormat
                                                fullWidth
                                                disabled={viewOnly}
                                                eventHandle={(e) => {
                                                    this.totalUpdate(e, true, formApi);
                                                }}
                                            />
                                            <TextField field="totallength" label="Total Length" disabled />
                                        </Grid>
                                    </Grid>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel>
                                <ExpansionPanelSummary
                                    className={classes.summary}
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header">
                                    <this.summaryPanel
                                        classes={classes}
                                        title="Notes"
                                        child={
                                            <>
                                                {formApi.values.issue && (
                                                    <Typography style={{ color: "red", marginLeft: 5 }} variant="h5">
                                                        Notes Exist
                                                    </Typography>
                                                )}
                                            </>
                                        }
                                    />
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <TextField
                                                multiline
                                                rows={3}
                                                field="comments"
                                                fullWidth
                                                disabled={viewOnly}>
                                                <HelpLabel
                                                    inputLabel="Summary Internal Notes"
                                                    helpText={
                                                        <div>
                                                            This text box can be used to record any general notes you
                                                            want to store about the system overall.
                                                        </div>
                                                    }
                                                />
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField multiline rows={3} field="history" fullWidth disabled={viewOnly}>
                                                <HelpLabel
                                                    inputLabel="Abstract About History"
                                                    helpText={
                                                        <div>
                                                            This text box can be used to record an abstract about the
                                                            history of the ditch. For example CD 2 and 5 were combined
                                                            by a proceeding to create a new system.
                                                        </div>
                                                    }
                                                />
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                multiline
                                                rows={3}
                                                field="public_notes"
                                                fullWidth
                                                disabled={viewOnly}>
                                                <HelpLabel
                                                    inputLabel="Summary Public Notes"
                                                    helpText={
                                                        <div>
                                                            This text box can be used to record any general notes you
                                                            want to store about the system overall for the public.
                                                        </div>
                                                    }
                                                />
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Checkbox
                                                field="issue"
                                                label="Issue Needs Consideration"
                                                disabled={viewOnly}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h5" className={classes.header}>
                                                Record Notes or Conversations About This System
                                            </Typography>
                                            <Divider className={classes.margin} />
                                            <RecordTable
                                                noScroll
                                                viewOnly={viewOnly}
                                                columnData={noteColumnData}
                                                data={notes}
                                                deletable={true}
                                                deleteRecord={(id) => this.setState({ noteDelete: true, noteID: id })}
                                                editable={false}
                                                creatable={true}
                                                addName="Add Note"
                                                addRecord={() => this.setState({ noteOpen: true })}
                                            />
                                        </Grid>
                                    </Grid>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            {formApi &&
                                formApi.values &&
                                (formApi.values.jurisdiction === "Judicial" ||
                                    formApi.values.jurisdiction === "Joint") && (
                                    <ExpansionPanel>
                                        <ExpansionPanelSummary
                                            className={classes.summary}
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header">
                                            <this.summaryPanel classes={classes} title="Joint and Judicial" />
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <Grid container spacing={3}>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        field="annual_authorization"
                                                        label="Annual Repairs w/o authorization should not exceed"
                                                        useTextFormat
                                                        fullWidth
                                                        disabled={viewOnly}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        field="commissioners_appointed"
                                                        fullWidth
                                                        disabled={viewOnly}>
                                                        <HelpLabel
                                                            inputLabel="Commissioners appointed to joint board"
                                                            helpText={<div>Comma seperated list</div>}
                                                        />
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h5" className={classes.header}>
                                                        Joint Drainage System List
                                                    </Typography>
                                                    <Divider className={classes.margin} />
                                                    <RecordTable
                                                        noScroll
                                                        viewOnly={viewOnly}
                                                        columnData={jointColumnData}
                                                        data={jointSystems}
                                                        deletable={true}
                                                        deleteRecord={(id) =>
                                                            this.setState({ jointID: id, jointDelete: true })
                                                        }
                                                        editable={true}
                                                        editRecord={(values) =>
                                                            this.setState({ jointOpen: true, activeJoint: values })
                                                        }
                                                        creatable={true}
                                                        addName="Add Item"
                                                        addRecord={() =>
                                                            this.setState({ jointOpen: true, activeJoint: {} })
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                )}
                            <ExpansionPanel>
                                <ExpansionPanelSummary
                                    className={classes.summary}
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header">
                                    <this.summaryPanel classes={classes} title="Annual Inspections" />
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <TextField
                                                field="inspectyear"
                                                label="Next Inspection Year"
                                                fullWidth
                                                disabled={viewOnly}
                                                useIntegerFormat></TextField>
                                            <Select
                                                options={InpsectionType}
                                                field="inspecttype"
                                                label="Inspection Type"
                                                fullWidth
                                                disabled={viewOnly}
                                            />
                                        </Grid>
                                        <Grid item xs={6} className={classes.mb}>
                                            <Typography variant="h6">Count Last Five Years</Typography>
                                            <div style={{ display: "inline-flex" }}>
                                                <Card className={classes.width}>
                                                    <CardContent className={classes.content}>
                                                        <Typography style={{ color: "white" }}>Repairs</Typography>
                                                        <Typography variant="h5">{tempCounts[0]}</Typography>
                                                    </CardContent>
                                                </Card>
                                                <Card className={classes.width}>
                                                    <CardContent className={classes.content}>
                                                        <Typography style={{ color: "white" }}>Violations</Typography>
                                                        <Typography variant="h5">{tempCounts[2]}</Typography>
                                                    </CardContent>
                                                </Card>
                                                <Card className={classes.width}>
                                                    <CardContent className={classes.content}>
                                                        <Typography style={{ color: "white" }}>Conditions</Typography>
                                                        <Typography variant="h5">{tempCounts[1]}</Typography>
                                                    </CardContent>
                                                </Card>
                                            </div>
                                        </Grid>
                                        <Button
                                            style={{ display: viewOnly && "none" }}
                                            className={classes.mb}
                                            onClick={() => {
                                                this.setState({
                                                    activeInspection: {},
                                                    annualOpen: true,
                                                    edit: false,
                                                    file: null,
                                                });
                                            }}
                                            variant="contained"
                                            color="primary"
                                            fullWidth>
                                            <AddCircleOutlineIcon />
                                            &nbsp;&nbsp;&nbsp;Add Past Inspection
                                        </Button>
                                        <CommonTable
                                            name="annuals"
                                            viewOnly={viewOnly}
                                            by="year"
                                            data={annuals}
                                            edit
                                            emptyTxt="No Annual Inspections Defined"
                                            columns={AnnualcolumnData}
                                            keys={AnnualkeyData}
                                            onClickEdit={this.annualOnClickEdit}
                                            onClickDelete={(l) => this.setState({ annualID: l.id, annualDelete: true })}
                                        />
                                    </Grid>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel>
                                <ExpansionPanelSummary
                                    className={classes.summary}
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header">
                                    <this.summaryPanel classes={classes} title="Benefits" />
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <TextField
                                                field="benefitacres"
                                                label="Benefited Acres"
                                                scale={3}
                                                useDecimalFormat
                                                fullWidth
                                                disabled={viewOnly}></TextField>
                                            <TextField
                                                field="origbenefitacres"
                                                label="Original Benefited Acres"
                                                scale={3}
                                                useDecimalFormat
                                                fullWidth
                                                disabled={viewOnly}></TextField>
                                            <TextField
                                                field="redeterbenefitacres"
                                                label="Most Recent Benefit Acres"
                                                scale={3}
                                                useDecimalFormat
                                                fullWidth
                                                disabled={viewOnly}></TextField>
                                            <TextField
                                                field="outletperc"
                                                label="Outlet %"
                                                userPercentageFormat
                                                fullWidth
                                                disabled={viewOnly}></TextField>
                                            <TextField
                                                field="roadacres"
                                                label="Road Authority Acres"
                                                scale={3}
                                                useDecimalFormat
                                                fullWidth
                                                disabled={viewOnly}></TextField>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                field="constructease"
                                                label="Construction Easement"
                                                useTextFormat
                                                fullWidth
                                                disabled={viewOnly}></TextField>
                                            <TextField
                                                field="origbenefitdollars"
                                                label="Original Benefited Amt"
                                                useTextFormat
                                                fullWidth
                                                disabled={viewOnly}></TextField>
                                            <TextField
                                                field="redeterbenefitdollars"
                                                label="Most Recent Benefit Amt"
                                                useTextFormat
                                                fullWidth
                                                disabled={viewOnly}></TextField>
                                            <TextField
                                                field="benefitpurpose"
                                                label="Benefit Amt for Assmt Purposes Only"
                                                useTextFormat
                                                fullWidth
                                                disabled={viewOnly}></TextField>
                                            <DatePicker
                                                field="rob"
                                                label="Most Recent Redetermination Date"
                                                disabled={viewOnly}
                                            />
                                        </Grid>
                                    </Grid>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel>
                                <ExpansionPanelSummary
                                    className={classes.summary}
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header">
                                    <this.summaryPanel classes={classes} title="Primary Location" />
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Grid container spacing={3}>
                                        <Grid item xs={8} sm={10} md={5}>
                                            <MultiSelect
                                                field="township"
                                                label="Township"
                                                options={MAKE_OPTIONS_KEY(towns, "name")}
                                                fullWidth
                                                disabled={viewOnly}
                                            />
                                        </Grid>
                                        <Grid item xs={4} sm={2} md={1}>
                                            <Tooltip
                                                title={
                                                    <React.Fragment>
                                                        <Typography color="inherit">Clear Township Value</Typography>
                                                    </React.Fragment>
                                                }>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    fullWidth
                                                    style={{ marginTop: "32px" }}
                                                    onClick={() => {
                                                        this.clearTownship(formApi);
                                                    }}>
                                                    <ClearIcon />
                                                </Button>
                                            </Tooltip>
                                        </Grid>

                                        {settings["jurisdiction"] === "County" && (
                                            <>
                                                <Grid item xs={8} sm={10} md={5}>
                                                    <MultiSelect
                                                        field="district"
                                                        label="Commissioner District"
                                                        options={MAKE_OPTIONS_KEY(commish, "district")}
                                                        fullWidth
                                                        disabled={viewOnly}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} sm={2} md={1}>
                                                    <Tooltip
                                                        title={
                                                            <React.Fragment>
                                                                <Typography color="inherit">
                                                                    Clear Commmissioner District Value
                                                                </Typography>
                                                            </React.Fragment>
                                                        }>
                                                        <Button
                                                            aria-label="delete"
                                                            variant="contained"
                                                            color="primary"
                                                            fullWidth
                                                            style={{ marginTop: "32px" }}
                                                            onClick={() => {
                                                                this.clearDistrict(formApi);
                                                            }}>
                                                            <ClearIcon />
                                                        </Button>
                                                    </Tooltip>
                                                </Grid>
                                            </>
                                        )}
                                        {settings["jurisdiction"] === "Watershed District" && (
                                            <Grid item xs={12}>
                                                <MultiSelect
                                                    field="county"
                                                    label="County"
                                                    options={MAKE_OPTIONS(settings["counties"].split(";"))}
                                                    fullWidth
                                                    disabled={viewOnly}
                                                />
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            <TextField
                                                multiline
                                                rows={3}
                                                field="details"
                                                label="Location Details"
                                                fullWidth
                                                disabled={viewOnly}></TextField>
                                        </Grid>
                                    </Grid>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </form>
                    )}
                </Form>
                <div style={{ height: 20, width: "100%" }}></div>
                <Dialog open={yearOpen} classes={{ paper: classes.minWidth }} maxWidth="sm" fullWidth>
                    <Toolbar>
                        <Typography variant="h5" className={classes.flex}>
                            System
                        </Typography>
                        <IconButton onClick={() => this.setState({ yearOpen: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <Form
                            id="assessment"
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            defaultValues={activeYear}
                            onSubmit={(values) => this.addAssessment(values)}>
                            {(formApi) => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                field="year"
                                                label="Year"
                                                useIntegerFormat
                                                fullWidth
                                                required={true}
                                            />
                                            <TextField
                                                field="assessment"
                                                label="Assessment"
                                                useTextFormat
                                                fullWidth
                                                required={true}
                                            />
                                            <TextField
                                                field="expand"
                                                label="Expenditure"
                                                useTextFormat
                                                fullWidth
                                                required={true}
                                            />
                                            <DatePicker field="uploaddate" label="Date" autoPopulate={true} />
                                            <Grid item xs={12} className={classes.mt}>
                                                <Button type="submit" variant="contained" color="primary">
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </DialogContent>
                </Dialog>
                <Dialog open={annualOpen} classes={{ paper: classes.minWidth }} maxWidth="md" fullWidth>
                    <Toolbar>
                        <Typography variant="h5" className={classes.flex}>
                            Annual Inspection
                        </Typography>
                        <IconButton disabled={loading} onClick={() => this.setState({ annualOpen: false, file: null })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <Form
                            id="annual"
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            validateError={this.errorValidator}
                            defaultValues={{ ...activeInspection, lock_submit: false }}
                            onSubmit={this.annualsubmitForm}>
                            {(formApi) => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                field="year"
                                                label="Year"
                                                useIntegerFormat
                                                fullWidth
                                                required={true}
                                            />
                                            <Select
                                                options={InpsectionType}
                                                field="inspecttype"
                                                label="Inspection Type"
                                                fullWidth
                                                required={true}
                                            />
                                            <TextField field="notes" label="Notes" className={classes.mb} fullWidth />
                                            {(!edit || !activeInspection.annualreport) && (
                                                <FileFormInput
                                                    disabled={loading}
                                                    eventHandle={(val) =>
                                                        this.handleFiles("annualreport", val, formApi)
                                                    }
                                                    field="annualreport"
                                                    id="annual_file_upl"
                                                    label="Attach Annual Report"
                                                />
                                            )}
                                            {confirmUpload && (
                                                <Subquestion
                                                    component={
                                                        <RadioGroup
                                                            field="lock_submit"
                                                            name="lock_submit"
                                                            label={
                                                                <HelpLabel
                                                                    inputLabel="This is a large file and may take a few minutes to upload, do you wish to continue?"
                                                                    title={true}
                                                                    showLabel={true}
                                                                    helpText={`This upload will approixmity take ${calculated}, but will vary depending on internet connection.`}
                                                                />
                                                            }
                                                            fullWidth
                                                            options={[
                                                                { label: "Yes", value: "true" },
                                                                { label: "No", value: "false" },
                                                            ]}
                                                            disabled={loading}
                                                            alignment={true}></RadioGroup>
                                                    }
                                                />
                                            )}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div className={classes.submitWrapper}>
                                                <Button
                                                    disabled={loading || (confirmUpload && !formApi.values.lock_submit)}
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.mt}>
                                                    Save
                                                    {loading && (
                                                        <CircularProgress size={24} className={classes.progress} />
                                                    )}
                                                </Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </DialogContent>
                </Dialog>
                <Dialog open={jointOpen} classes={{ paper: classes.minWidth }} maxWidth="sm" fullWidth>
                    <Toolbar>
                        <Typography variant="h5" className={classes.flex}>
                            Item
                        </Typography>
                        <IconButton onClick={() => this.setState({ jointOpen: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <Form
                            id="checkbook"
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            validateError={this.jointErrorValidator}
                            defaultValues={activeJoint}
                            onSubmit={(values) => this.addJoint(values)}>
                            {(formApi) => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Select
                                                field="county"
                                                label="County"
                                                options={jointOptions}
                                                fullWidth
                                                required={true}
                                            />
                                            <TextField
                                                field="benefit_amt"
                                                label="Benefit Amount"
                                                useTextFormat
                                                fullWidth
                                            />
                                            <TextField
                                                field="percentage"
                                                label="Percentage"
                                                userPercentageFormat
                                                fullWidth
                                            />
                                            <TextField multiline rows={3} field="notes" label="Notes" fullWidth />
                                            <Grid item xs={12} className={classes.mt}>
                                                <Button type="submit" variant="contained" color="primary">
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </DialogContent>
                </Dialog>
                <Dialog open={noteOpen} classes={{ paper: classes.minWidth }} maxWidth="sm" fullWidth>
                    <Toolbar>
                        <Typography variant="h5" className={classes.flex}>
                            Note
                        </Typography>
                        <IconButton onClick={() => this.setState({ noteOpen: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <Form
                            id="notes"
                            dontValidateOnMount={true}
                            defaultValues={this.defaultValues()}
                            onSubmit={this.saveNote}>
                            {(formApi) => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <DatePicker
                                                label="Date"
                                                key="currentdate"
                                                field="currentdate"
                                                disabled={viewOnly}
                                                autoPopulate={true}
                                            />
                                            <Checkbox
                                                className={classes.margin}
                                                field="followup"
                                                key="follow_up"
                                                label="Follow-Up Needed"
                                                disabled={viewOnly}
                                            />
                                            <TextField
                                                field="person"
                                                label="Person Recording notes"
                                                fullWidth
                                                disabled={viewOnly}
                                            />
                                            <TextField
                                                field="notes"
                                                label="Notes"
                                                fullWidth
                                                multiline
                                                rows={3}
                                                disabled={viewOnly}
                                            />
                                            <Grid item xs={12} className={classes.mt}>
                                                <Button type="submit" variant="contained" color="primary">
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </DialogContent>
                </Dialog>
                <WarningDialog
                    confirmAction={() => {
                        ormDrainagenoteDeletePromise(noteID).then((id) => {
                            if (!id) {
                                saveBad();
                            } else {
                                saveGood();
                            }
                        });
                        this.setState({ noteDelete: false });
                    }}
                    cancelAction={() => this.setState({ noteDelete: false })}
                    open={noteDelete}
                    title="Delete Note"
                    text={"Are you sure you wish to permanently delete?"}
                />
                <WarningDialog
                    confirmAction={() => {
                        ormDrainageAnnualDeletePromise(annualID).then((id) => {
                            if (!id) {
                                saveBad();
                            } else {
                                saveGood();
                            }
                            this.setState({ annualDelete: false });
                        });
                        updateSystem();
                    }}
                    cancelAction={() => {
                        this.setState({ annualDelete: false });
                    }}
                    confirmText="Delete"
                    cancelText="Cancel"
                    open={annualDelete}
                    title={"Delete Annual Inspection"}
                    text={"Are you sure you wish to delete?"}
                />
                <WarningDialog
                    confirmAction={() => {
                        ormJointSystemDeletePromise(jointID).then((id) => {
                            if (!id) {
                                saveBad();
                            } else {
                                saveGood();
                            }
                            this.setState({ jointDelete: false });
                        });
                        updateSystem();
                    }}
                    cancelAction={() => {
                        this.setState({ jointDelete: false });
                    }}
                    confirmText="Delete"
                    cancelText="Cancel"
                    open={jointDelete}
                    title={"Delete Joint System"}
                    text={"Are you sure you wish to delete?"}
                />
            </div>
        );
    }
}

Description = connect(
    (state, ownProps) => ({
        authState: state.auth,
        system: getDrainage(state, ownProps),
        jointSystems: getJointSystem(state, ownProps),
        notes: getNotes(state, ownProps),
        annuals: getDrainageAnnuals(state, ownProps),
        commish: getCommish(state),
        settings: getMapSetting(state),
        towns: getTown(state),
    }),
    {
        ...DrainageSystem.actions,
        ...DrainageAnnual.actions,
        ...Drainagenote.actions,
        ...JointSystem.actions,
    }
)(Description);

export default withStyles(styles)(withRouter(Description));
