import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import { Scrollbars } from "react-custom-scrollbars";
import { connect } from "react-redux";
import { formateDateSlash } from "../../api/utils";
import { Form } from "react-form";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableFooter from "@material-ui/core/TableFooter";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ListItemText from "@material-ui/core/ListItemText";
import Input from "@material-ui/core/Input";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import AssessmentIcon from "@material-ui/icons/Assessment";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from "@material-ui/icons/Search";

import Divider from "@material-ui/core/Divider";
import ToolBar from "@material-ui/core/Toolbar";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";

import { ExcelIcon, ShapeIcon } from "../common/IconFunctions";

import AppContainer from "../common/AppContainer";
import CustomTableCell from "../common/TableCell";
import EnhancedTableHead from "../common/EnhancedTableHead";
import TablePaginationActionsWrapped from "../common/Paginator";
import DatePicker from "..//common/DatePicker";
import SnackBarError from "../common/SnackBarError";

import { Priority } from "../common/SelectOptions";
import { createSelector } from "../common/orm";

import * as authActions from "../auth/actions";

const getDrainageSystem = createSelector((session) => {
    return session.DrainageSystemView.all().orderBy("drainage", "asc").toRefArray();
});
const getCommissioners = createSelector((session) => {
    return session.Local_commish.all().orderBy("district", "asc").toRefArray();
});

const getUsers = createSelector((session) => {
    return session.User.filter((x) => x.role === "Contractor").toRefArray();
});
const getMapSetting = createSelector((schema) => {
    return schema.MapSetting.first();
});

const styles = (theme) => ({
    space: {
        marginTop: 48,
    },
    summary: {
        backgroundColor: "rgb(221, 221, 221)",
    },
    mr: {
        marginRight: 5,
    },
    expansionIcon: {
        marginTop: 6,
        marginBottom: -6,
        marginRight: 8,
    },
    selectbox: {
        margin: "5px 0px 10px 0px",
        backgroundColor: "#fff",
        border: "1px solid #ccc",
        borderRadius: 5,
        padding: "4px 5px 4px 5px",
        width: "95%",
        maxWidth: "700px",
    },
    listselectbox: {
        margin: "5px 0px 10px 0px",
        backgroundColor: "#fff",
        border: "1px solid #ccc",
        borderRadius: 5,
        padding: "4px 5px 4px 5px",
        width: "95%",
        maxWidth: "300px",
    },
    root: {
        "& > *": {
            margin: theme.spacing(1),
        },
    },
    submitWrapper: {
        position: "relative",
    },
    progress: {
        marginLeft: 5,
        color: "#4CAF50",
    },
    centerAlign: {
        textAlign: "center",
    },
});

const Activites = ["Repair", "Violation", "Condition", "Bmp"];
const types = ["Open Ditch", "Tile", "Both"];

const StatusList = [
    "For Review",
    "In Progress",
    "Closed",
    "For Review, In Progress",
    "For Review, Closed",
    "In Progress, Closed",
];
const StatusBmp = ["Existing", "Recommended"];
const StatusViolation = ["Compliant", "Non-Compliant"];

const AllColumns = [
    { pos: 0, label: "System Name", id: "drainage_name", allowSort: true },
    { pos: 2, label: "Branch", id: "branch", allowSort: true },
    { pos: 3, label: "Commissioner District", id: "commissioner", allowSort: true },
    { pos: 4, label: "Township", id: "township", allowSort: true },
    { pos: 5, label: "Section", id: "section", allowSort: true },
    { pos: 6, label: "Qtr-Qtr", id: "quarter", allowSort: true },
    { pos: 7, label: "LandOwner", id: "landowner", allowSort: true },
    { pos: 18, label: "Has Mapped Location", id: "thegeom", allowSort: true },
];

const RepairColumns = [
    { pos: 1, label: "Repair", id: "repair", allowSort: true },
    { pos: 8, label: "Status", id: "status", allowSort: true },
    { pos: 9, label: "Priority", id: "priority", allowSort: true },
    { pos: 10, label: "Date Created", id: "date", allowSort: true },
    { pos: 11, label: "Person Requesting Repair", id: "request", allowSort: true },
    { pos: 12, label: "Contractor", id: "contractor", allowSort: true },
    { pos: 13, label: "Total Estimate Cost", id: "estimate_total", allowSort: true },
    { pos: 14, label: "Total Invoice Cost", id: "invoice_total", allowSort: true },
    { pos: 15, label: "Proposed Work", id: "work", allowSort: true },
    { pos: 16, label: "FEMA Event", id: "fema_event", allowSort: true },
    { pos: 17, label: "FEMA Date", id: "fema_date", allowSort: true },
    { pos: 17, label: "Assigned Contractor", id: "assigned_contractor", allowSort: true },
];

const ViolationColumns = [
    { pos: 1, label: "Violation #", id: "vnum", allowSort: true },
    { pos: 8, label: "Source Date", id: "source_date", allowSort: true },
    { pos: 9, label: "Violation Type", id: "violation_type", allowSort: true },
    { pos: 10, label: "Next Scheduled Inspection Date", id: "next_compliance_inspection", allowSort: true },
];

const ConditionColumns = [
    { pos: 1, label: "Condition #", id: "cnum", allowSort: true },
    { pos: 8, label: "Date Created", id: "date", allowSort: true },
    { pos: 9, label: "Monitor", id: "monitor", allowSort: true },
    { pos: 10, label: "Notes", id: "notes", allowSort: true },
];

const BmpColumns = [
    { pos: 1, label: "BMP #", id: "bmpnum", allowSort: true },
    { pos: 8, label: "Date Created", id: "date", allowSort: true },
    { pos: 9, label: "Status", id: "status", allowSort: true },
    { pos: 10, label: "BMP Type", id: "bmp_type", allowSort: true },
    { pos: 11, label: "Notes", id: "notes", allowSort: true },
];

const columnData = [
    { id: "key", numeric: false, label: "X", allowSort: false, bool: true },
    { id: "label", numeric: false, label: "Include Column", allowSort: true },
];

function FormatDate(date) {
    return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1 < 10 ? "0" : "") +
        (date.getMonth() + 1) +
        "-" +
        ((date.getDate() < 10 ? "0" : "") + date.getDate())
    );
}

class ActivitySearch extends Component {
    state = {
        noResults: false,
        snackbarOpen: false,
        activity: "Repair",
        range: "Current",
        status: "For Review",

        page: 0,
        rowsPerPage: 100,
        data: [],
        loading: false,

        dateError: false,
        dateMessage: "One or more dates are invalid",
        start: FormatDate(new Date()),
        end: FormatDate(new Date()),

        drainage_str: this.props.systems[0] ? this.props.systems[0].drainage : "",
        status_str: "For Review",
        monitor_str: "Yes",
        dist_str: this.props.commissioners[0] ? this.props.commissioners[0].district : "",
        county_str: "",
        priority_str: "High",
        repair_type_str: "Open Ditch",
        invoice_str: "Empty",

        drainage: false,
        status_c: false,
        monitor: false,
        _county: false,
        district: false,
        priority_c: false,
        repair_type: false,
        fema: false,
        assigned_contractor: false,
        monitor_check: false,
        invoice_check: false,
        thegeom_check: false,
        thegeom_no_check: false,
        all_check: false,

        drainage_name: true,
        repair: true,
        branch: false,
        commissioner: true,
        date: true,
        source_date: true,
        township: true,
        section: true,
        quarter: true,
        priority: true,
        request: true,
        vnum: true,
        violation_type: true,
        next_compliance_inspection: true,
        thegeom: true,
        cnum: true,
        notes: true,
        bmpnum: true,
        bmp_notes: true,
        descending: false,
        sort_column: "",
        searched: false,
    };

    handleActivityChange = (e) => {
        var status_str = "";
        if (e.target.value === "Repair") {
            status_str = "For Review";
        } else if (e.target.value === "Violation") {
            status_str = "Compliant";
        } else if (e.target.value === "Bmp") {
            status_str = "Existing";
        }

        this.setState({ activity: e.target.value, data: [], status_str, searched: false, page: 0 });
    };

    handleChange = (e, key) => {
        this.setState({ [key]: e.target.value });
    };

    handleChangeDrainage = (e, key) => {
        const { systems } = this.props;
        const index = systems.findIndex((x) => x.drainage === e.target.value);

        if (index > -1) {
            this.setState({ [key]: e.target.value, drainage_id: systems[index].id });
        } else {
            this.setState({ [key]: e.target.value });
        }
    };

    handleCheckBox = (e, key) => {
        if (key === "thegeom_check" && e.target.checked) {
            this.setState({
                thegeom_check: true,
                thegeom_no_check: false,
            });
        } else if (key === "thegeom_no_check" && e.target.checked) {
            this.setState({
                thegeom_check: false,
                thegeom_no_check: true,
            });
        } else {
            this.setState({ [key]: e.target.checked });
        }
    };

    handleRange = (e) => {
        let temp = { range: e.target.value, all_check: false };
        if (e.target.value === "All") {
            temp.all_check = true;
        }
        this.setState(temp);
    };

    getQueryString = () => {
        const { settings } = this.props;

        var arr = [];

        arr.push(`activity=${this.state.activity}`);
        arr.push(`range=${this.state.range}&start=${this.state.start}&end=${this.state.end}`);
        arr.push(`drainage=${this.state.drainage}&drainage_str=${this.state.drainage_str}`);
        arr.push(`status=${this.state.status_c}&status_str=${this.state.status_str}`);
        arr.push(`monitor=${this.state.monitor_check}&monitor_str=${this.state.monitor_str}`);
        arr.push(`priority=${this.state.priority_c}&priority_str=${this.state.priority_str}`);
        arr.push(`repair_type=${this.state.repair_type}&repair_type_str=${this.state.repair_type_str}`);
        arr.push(`fema=${this.state.fema}`);
        arr.push(`thegeom=${this.state.thegeom_check}`);
        arr.push(`thegeom_no_check=${this.state.thegeom_no_check}`);
        arr.push(`contractor=${this.state.thegeom_check}`);
        arr.push(
            `assigned_contractor=${this.state.assigned_contractor_check}&assigned_contractor_str=${this.state.assigned_contractor_str}`
        );
        arr.push(`invoice=${this.state.invoice_check}`);
        arr.push(`invoice_str=${this.state.invoice_str}`);
        arr.push(`all=${this.state.all_check}`);
        arr.push(`order_by=${this.state.sort_column}&descending=${this.state.descending}`);

        if (settings) {
            if (settings.jurisdiction === "Watershed District") {
                arr.push(`county=${this.state._county}&county_str=${this.state.county_str}`);
            } else {
                arr.push(`commissioner=${this.state.district}&dist_str=${this.state.dist_str}`);
            }
        }

        return arr.join("&");
    };

    search = async (e, sorting = false) => {
        const { authState } = this.props;

        if (this.state.range === "Date Range") {
            const start_error =
                this.state.start && (this.state.start === "Invalid Date" || this.state.start === "Invalid Year");
            const end_error =
                this.state.end && (this.state.end === "Invalid Date" || this.state.end === "Invalid Year");
            const start_required = this.state.start && this.state.start === "Required";
            const end_required = this.state.end && this.state.end === "Required";

            if (start_error || end_error) {
                this.setState({
                    dateError: true,
                    data: [],
                    dateMessage: "One or more dates are invalid",
                });
                return;
            } else if (start_required || end_required) {
                this.setState({
                    dateError: true,
                    data: [],
                    dateMessage: "One or more dates require input",
                });
                return;
            } else {
                this.setState({ loading: true });
            }
        } else {
            this.setState({ loading: true, data: [] });
        }

        if (!sorting) {
            await this.setState({ sort_column: "", descending: false });
        }

        var querystring = this.getQueryString();

        fetch(`/advancedsearch?${querystring}`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Token " + authState.user.auth_token,
            },
        })
            .then((result) => {
                if (!result.ok) {
                    return result.text().then((text) => {
                        throw new Error(text);
                    });
                } else {
                    return result.json();
                }
            })
            .then((data) => {
                this.setState({
                    data,
                    searched: data.length > 0,
                    loading: false,
                    noResults: data && data.length === 0,
                });
            })
            .catch((e) => {
                var message = e.message;
                if (message && message === '{"detail":"Token has expired."}') {
                    this.props.authLogout();
                    this.props.history.push("/");
                } else {
                    this.setState({ data: [], loading: false });
                }
            });
    };

    renderThumb({ style, ...props }) {
        const thumbStyle = {
            backgroundColor: "#e1d4d8",
        };
        return <div style={{ ...style, ...thumbStyle }} />;
    }
    renderThumb2({ style, ...props }) {
        const thumbStyle = {
            display: "none",
        };
        return <div style={{ ...style, ...thumbStyle }} />;
    }

    renderThumb1({ style, ...props }) {
        const thumbStyle = {
            zIndex: "9999",
            backgroundColor: "#e1d4d8",
            cursor: "pointer",
            width: 20,
        };
        return <div style={{ ...style, ...thumbStyle }} />;
    }

    renderThumb12({ style, ...props }) {
        const thumbStyle = {
            zIndex: "999",
            backgroundColor: "#e1d4d8",
            cursor: "pointer",
            height: 20,
        };
        return <div style={{ ...style, ...thumbStyle }} />;
    }

    handleChangePage = (event, page) => {
        this.setState({ page });
    };
    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value, page: 0 });
    };

    handleRequestSort = async (column, all = null) => {
        const { activity, sort_column, descending, searched, data } = this.state;
        if (!searched) {
            return;
        }

        let key = "";
        let dataType = "string";

        switch (column) {
            case "System Name":
                key = "drainage_name";
                break;
            case "Branch":
                key = "branch";
                break;
            case "Repair":
                key = "repair";
                break;
            case "Violation #":
                key = "vnum";
                break;
            case "Condition #":
                key = "cnum";
                break;
            case "BMP #":
                key = "bmpnum";
                break;
            case "Commissioner District":
                key = "commissioner";
                break;
            case "County":
                key = "county";
                break;
            case "Township":
                key = "township";
                break;
            case "Section":
                key = "section";
                break;
            case "Qtr-Qtr":
                key = "quarter";
                break;
            case "Date Created":
                dataType = "date";
                switch (activity) {
                    case "Repair":
                        key = "date";
                        break;
                    case "Condition":
                        key = "date";
                        break;
                    case "Bmp":
                        key = "date";
                        break;
                }
                break;
            case "Source Date":
                // key = "violationdate";
                dataType = "number";
                key = "source_date";
                break;
            case "Person Requesting Repair":
                key = "request";
                break;
            case "Status":
                key = "status";
                break;
            case "Priority":
                key = "priority";
                break;
            case "LandOwner":
                key = "landowner";
                break;
            case "Contractor":
                key = "contractor";
                break;
            case "Total Estimate Cost":
                key = "estimate_total";
                dataType = "number";
                break;
            case "Total Invoice Cost":
                key = "invoice_total";
                dataType = "number";
                break;
            case "Proposed Work":
                key = "work";
                break;
            case "FEMA Event":
                key = "fema_event";
                break;
            case "FEMA Date":
                dataType = "number";
                key = "fema_date";
                break;
            case "Assigned Contractor":
                key = "assigned_contractor";
                break;
            case "Has Mapped Location":
                key = "thegeom";
                break;
            case "Violation Type":
                key = "violation_type";
                break;
            case "Next Scheduled Inspection Date":
                key = "next_compliance_inspection";
                break;
            case "Notes":
                key = "notes";
                break;
            case "Monitor":
                key = "monitor";
                break;
            case "BMP Type":
                key = "bmp_type";
                break;
        }

        if (key) {
            const desc = key === sort_column && !descending;
            const sortCol = key;

            if (desc) {
                if (dataType === "string") {
                    data.sort((a, b) => (b[sortCol].toLowerCase() < a[sortCol].toLowerCase() ? -1 : 1));
                } else {
                    data.sort((a, b) => (b[sortCol] < a[sortCol] ? -1 : 1));
                }
                // data.sort((a, b) => (b[sortCol] < a[sortCol] ? -1 : 1));
            } else {
                if (dataType === "string") {
                    data.sort((a, b) => (a[sortCol].toLowerCase() < b[sortCol].toLowerCase() ? -1 : 1));
                } else {
                    data.sort((a, b) => (a[sortCol] < b[sortCol] ? -1 : 1));
                }
                // data.sort((a, b) => (a[sortCol] < b[sortCol] ? -1 : 1));
            }

            this.setState({ descending: desc, sort_column: sortCol, data: data });

            // await this.search('', true)
        }
    };

    goActivity = (x) => {
        const { history } = this.props;
        const { activity } = this.state;
        if (activity === "Repair") history.push(`/repair/${x.id}/${x.drainage_id}/${x.drainage_name}`);
        else if (activity === "Violation") history.push(`/violation/${x.id}/${x.drainage_id}/${x.drainage_name}`);
        else if (activity === "Condition") history.push(`/condition/${x.id}/${x.drainage_id}/${x.drainage_name}`);
        else if (activity === "Bmp") history.push(`/bmp/${x.id}/${x.drainage_id}/${x.drainage_name}`);
    };

    display = (x, z) => {
        if (z.id === "estimate_total" || z.id === "invoice_total") {
            return `$${x[z.id].toLocaleString(undefined, { minimumFractionDigits: 2 })}`;
        } else if (z.id === "date" || z.id === "source_date") {
            return x[z.id] ? formateDateSlash(x[z.id]) : "-";
        } else {
            return x[z.id] ? x[z.id] : "-";
        }
    };

    setDate = (val, key) => {
        const date = new Date(val);
        var formatDate = FormatDate(date);
        if (formatDate !== "NaN-NaN-NaN") {
            this.setState({ [key]: formatDate });
        } else {
            this.setState({ [key]: val });
        }
    };

    render() {
        const { classes, commissioners, systems, settings, users } = this.props;
        const {
            noResults,
            activity,
            range,

            data,
            loading,

            dateError,
            dateMessage,

            drainage_str,
            status_str,
            monitor_str,
            invoice_str,
            county_str,
            dist_str,
            priority_str,
            repair_type_str,
            contractor_str,

            drainage,
            status_c,
            _county,
            district,
            priority_c,
            repair_type,
            fema,
            assigned_contractor_check,
            monitor_check,
            invoice_check,
            thegeom_check,
            thegeom_no_check,

            order,
            orderBy,
            rowsPerPage,
            page,
            tableOrder,
            tableOrderBy,
            searched,
        } = this.state;

        var arr = [];
        var status_options = [];
        if (activity === "Repair") {
            arr = RepairColumns;
            status_options = StatusList;
        } else if (activity === "Violation") {
            arr = ViolationColumns;
            status_options = StatusViolation;
        } else if (activity === "Condition") {
            arr = ConditionColumns;
        } else if (activity === "Bmp") {
            arr = BmpColumns;
            status_options = StatusBmp;
        }

        if (settings) {
            if (settings.jurisdiction === "Watershed District") {
                AllColumns[2] = { pos: 3, label: "County", id: "county", allowSort: true };
            }
            if (settings.jurisdiction === "County") {
                AllColumns[2] = { pos: 3, label: "Commissioner District", id: "commissioner", allowSort: true };
            }
        }

        const combined = AllColumns.concat(arr).sort((a, b) => a.pos - b.pos);

        const includedColumns = combined.filter((x) => this.state[x.id.toString()]);

        const include_shape = includedColumns.map((x) => x.id);
        const include = includedColumns.map((x) => x.id + "1" + x.label);
        var list = this.getQueryString() + "&list=" + include.join(",").replace("#", "%23"); //  need to encode #
        var shape_list = this.getQueryString() + "&list=" + include_shape.join(",").replace("#", "%23");

        return (
            <AppContainer authenticated adminPage={true}>
                <div className={classes.space}>
                    <ToolBar>
                        <Typography variant="h5" className={classes.grow}>
                            Activity Search/Report
                        </Typography>
                    </ToolBar>
                    <Divider className={classes.mb} />
                    <ExpansionPanel defaultExpanded={true} style={{ marginTop: 10 }}>
                        <ExpansionPanelSummary className={classes.summary} expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h5">
                                <AssessmentIcon className={classes.expansionIcon} /> Search Filters
                            </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid container spacing={10}>
                                <Grid item xs={12} md={9} lg={9} xl={8}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <Typography id="proposal-activity-label">Activity Type</Typography>
                                            <Select
                                                className={classes.selectbox}
                                                labelId="proposal-activity-label"
                                                id="proposal-activity"
                                                value={activity}
                                                onChange={this.handleActivityChange}
                                                input={<Input />}>
                                                {Activites.map((x) => (
                                                    <MenuItem key={x} value={x}>
                                                        <ListItemText primary={x} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography id="proposal-years-checkbox-label">Select Year(s) :</Typography>
                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    row
                                                    aria-label="master"
                                                    name="master1"
                                                    value={range}
                                                    onChange={this.handleRange}>
                                                    <FormControlLabel
                                                        value="Current"
                                                        control={<Radio color="primary" />}
                                                        label="Current"
                                                    />
                                                    <FormControlLabel
                                                        value="Date Range"
                                                        control={<Radio color="primary" />}
                                                        label="Date Range"
                                                    />
                                                    <FormControlLabel
                                                        value="All"
                                                        control={<Radio color="primary" />}
                                                        label="All"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                            <Form>
                                                {(formApi) => (
                                                    <form>
                                                        <div>
                                                            <DatePicker
                                                                disabled={range === "Current" || range === "All"}
                                                                field="start"
                                                                autoPopulate
                                                                eventHandle={(val) => this.setDate(val, "start")}
                                                            />
                                                        </div>
                                                        <div>
                                                            <DatePicker
                                                                disabled={range === "Current" || range === "All"}
                                                                field="end"
                                                                autoPopulate
                                                                eventHandle={(val) => this.setDate(val, "end")}
                                                            />
                                                        </div>
                                                    </form>
                                                )}
                                            </Form>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid xs={12} md={9} lg={9} xl={8}>
                                            <List>
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            inputProps={{ "aria-label": "Drainage system search" }}
                                                            onChange={(e) => this.handleCheckBox(e, "drainage")}
                                                            color="primary"
                                                            checked={drainage}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Drainage System" />
                                                    <Select
                                                        className={classes.listselectbox}
                                                        inputProps={{ "aria-label": "Drainage System" }}
                                                        value={drainage_str}
                                                        onChange={(e) => this.handleChangeDrainage(e, "drainage_str")}
                                                        input={<Input />}>
                                                        {systems.map((x) => (
                                                            <MenuItem key={x.drainage + "drainage"} value={x.drainage}>
                                                                <ListItemText primary={x.drainage} />
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            inputProps={{ "aria-label": "Status search" }}
                                                            onChange={(e) => this.handleCheckBox(e, "status_c")}
                                                            color="primary"
                                                            checked={status_c}
                                                            disabled={activity === "Condition"}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Status" />
                                                    <Select
                                                        className={classes.listselectbox}
                                                        inputProps={{ "aria-label": "Status" }}
                                                        value={status_str}
                                                        onChange={(e) => this.handleChange(e, "status_str")}
                                                        disabled={activity === "Condition"}
                                                        input={<Input />}>
                                                        {status_options.map((x) => (
                                                            <MenuItem key={x + "status"} value={x}>
                                                                <ListItemText primary={x} />
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            inputProps={{ "aria-label": "Assigned contractor search" }}
                                                            onChange={(e) =>
                                                                this.handleCheckBox(e, "assigned_contractor_check")
                                                            }
                                                            color="primary"
                                                            checked={assigned_contractor_check}
                                                            disabled={activity !== "Repair"}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Assigned Contractor" />
                                                    <Select
                                                        className={classes.listselectbox}
                                                        inputProps={{ "aria-label": "Assigned Contractor" }}
                                                        value={contractor_str}
                                                        onChange={(e) =>
                                                            this.handleChange(e, "assigned_contractor_str")
                                                        }
                                                        disabled={activity !== "Repair"}
                                                        input={<Input />}>
                                                        <MenuItem key="All" value={-1}>
                                                            <ListItemText primary="All" />
                                                        </MenuItem>
                                                        {users.map((x) => (
                                                            <MenuItem key={x.id} value={x.id}>
                                                                <ListItemText primary={x.name} />
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            inputProps={{ "aria-label": "Invoice status search" }}
                                                            onChange={(e) => this.handleCheckBox(e, "invoice_check")}
                                                            color="primary"
                                                            checked={invoice_check}
                                                            disabled={activity !== "Repair"}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Invoice Status" />
                                                    <Select
                                                        className={classes.listselectbox}
                                                        inputProps={{ "aria-label": "Invoice Status" }}
                                                        value={invoice_str}
                                                        onChange={(e) => this.handleChange(e, "invoice_str")}
                                                        disabled={activity !== "Repair"}
                                                        input={<Input />}>
                                                        <MenuItem key={"Empty"} value={"Empty"}>
                                                            <ListItemText primary={"Empty"} />
                                                        </MenuItem>
                                                        <MenuItem key={"Submitted"} value={"Submitted"}>
                                                            <ListItemText primary={"Submitted"} />
                                                        </MenuItem>
                                                        <MenuItem key={"Paid"} value={"Paid"}>
                                                            <ListItemText primary={"Paid"} />
                                                        </MenuItem>
                                                    </Select>
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            inputProps={{ "aria-label": "Condition monitored search" }}
                                                            onChange={(e) => this.handleCheckBox(e, "monitor_check")}
                                                            color="primary"
                                                            checked={monitor_check}
                                                            disabled={activity !== "Condition"}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Monitor" />
                                                    <Select
                                                        className={classes.listselectbox}
                                                        inputProps={{ "aria-label": "Monitor" }}
                                                        value={monitor_str}
                                                        onChange={(e) => this.handleChange(e, "monitor_str")}
                                                        disabled={activity !== "Condition"}
                                                        input={<Input />}>
                                                        <MenuItem key={"Yes"} value={"Yes"}>
                                                            <ListItemText primary={"Yes"} />
                                                        </MenuItem>
                                                        <MenuItem key={"No"} value={"No"}>
                                                            <ListItemText primary={"No"} />
                                                        </MenuItem>
                                                    </Select>
                                                </ListItem>
                                                {settings && (
                                                    <>
                                                        {settings.jurisdiction === "Watershed District" && (
                                                            <ListItem>
                                                                <ListItemIcon>
                                                                    <Checkbox
                                                                        inputProps={{ "aria-label": "County search" }}
                                                                        onChange={(e) =>
                                                                            this.handleCheckBox(e, "_county")
                                                                        }
                                                                        color="primary"
                                                                        checked={_county}
                                                                    />
                                                                </ListItemIcon>
                                                                <ListItemText primary="County" />
                                                                <Select
                                                                    className={classes.listselectbox}
                                                                    inputProps={{ "aria-label": "County" }}
                                                                    value={county_str}
                                                                    onChange={(e) => this.handleChange(e, "county_str")}
                                                                    input={<Input />}>
                                                                    {settings["counties"].split(";").map((x) => (
                                                                        <MenuItem key={x} value={x}>
                                                                            <ListItemText primary={x} />
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </ListItem>
                                                        )}
                                                        {settings.jurisdiction === "County" && (
                                                            <ListItem>
                                                                <ListItemIcon>
                                                                    <Checkbox
                                                                        inputProps={{
                                                                            "aria-label":
                                                                                "Commissioner District search",
                                                                        }}
                                                                        onChange={(e) =>
                                                                            this.handleCheckBox(e, "district")
                                                                        }
                                                                        color="primary"
                                                                        checked={district}
                                                                    />
                                                                </ListItemIcon>
                                                                <ListItemText primary="Commissioner District" />
                                                                <Select
                                                                    className={classes.listselectbox}
                                                                    inputProps={{
                                                                        "aria-label": "Commissioner District",
                                                                    }}
                                                                    value={dist_str}
                                                                    onChange={(e) => this.handleChange(e, "dist_str")}
                                                                    input={<Input />}>
                                                                    {commissioners.map((x) => (
                                                                        <MenuItem key={x.district} value={x.district}>
                                                                            <ListItemText primary={x.district} />
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </ListItem>
                                                        )}
                                                    </>
                                                )}
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            inputProps={{ "aria-label": "Priority search" }}
                                                            onChange={(e) => this.handleCheckBox(e, "priority_c")}
                                                            color="primary"
                                                            checked={priority_c}
                                                            disabled={activity !== "Repair"}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Priority" />
                                                    <Select
                                                        className={classes.listselectbox}
                                                        inputProps={{ "aria-label": "Priority" }}
                                                        value={priority_str}
                                                        onChange={(e) => this.handleChange(e, "priority_str")}
                                                        disabled={activity !== "Repair"}
                                                        input={<Input />}>
                                                        {Priority.map((x) => (
                                                            <MenuItem key={x.label} value={x.label}>
                                                                <ListItemText primary={x.label} />
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            inputProps={{ "aria-label": "Repair Type search" }}
                                                            onChange={(e) => this.handleCheckBox(e, "repair_type")}
                                                            color="primary"
                                                            checked={repair_type}
                                                            disabled={activity !== "Repair"}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Repair Type" />
                                                    <Select
                                                        className={classes.listselectbox}
                                                        inputProps={{ "aria-label": "Repair Type" }}
                                                        value={repair_type_str}
                                                        onChange={(e) => this.handleChange(e, "repair_type_str")}
                                                        disabled={activity !== "Repair"}
                                                        input={<Input />}>
                                                        {types.map((x) => (
                                                            <MenuItem key={x} value={x}>
                                                                <ListItemText primary={x} />
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            inputProps={{ "aria-label": "FEMA Event search" }}
                                                            onChange={(e) => this.handleCheckBox(e, "fema")}
                                                            color="primary"
                                                            disabled={activity !== "Repair"}
                                                            checked={fema}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText primary="FEMA Event" />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            inputProps={{ "aria-label": "Has geometry search" }}
                                                            onChange={(e) => this.handleCheckBox(e, "thegeom_check")}
                                                            color="primary"
                                                            checked={thegeom_check}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Has Mapped Location" />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            inputProps={{ "aria-label": "Has geometry search" }}
                                                            onChange={(e) => this.handleCheckBox(e, "thegeom_no_check")}
                                                            color="primary"
                                                            checked={thegeom_no_check}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Does NOT have Mapped Location" />
                                                </ListItem>
                                            </List>
                                            <Button
                                                onClick={this.search}
                                                variant="contained"
                                                color="secondary"
                                                startIcon={<SearchIcon />}>
                                                Search
                                                {loading && <CircularProgress size={24} className={classes.progress} />}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3} xl={4}>
                                    <Table style={{ width: "auto" }}>
                                        <Scrollbars
                                            style={{ minWidth: 200, height: 400 }}
                                            renderThumbVertical={this.renderThumb}
                                            renderThumbHorizontal={this.renderThumb2}>
                                            <EnhancedTableHead
                                                columnData={columnData}
                                                order={tableOrder}
                                                orderBy={tableOrderBy}
                                                onRequestSort={() => {}}
                                            />
                                            <TableBody>
                                                {combined.map((x) => (
                                                    <TableRow key={x.id}>
                                                        <CustomTableCell>
                                                            <Checkbox
                                                                inputProps={{
                                                                    "aria-label": `Include Column ${x.label}`,
                                                                }}
                                                                onChange={(e) => this.handleCheckBox(e, x.id)}
                                                                color="primary"
                                                                checked={this.state[x.id.toString()]}
                                                            />
                                                        </CustomTableCell>
                                                        <CustomTableCell>{x.label}</CustomTableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Scrollbars>
                                    </Table>
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <Grid container spacing={3} style={{ marginTop: 10 }}>
                        <Grid item xs={12} md={10} lg={8} xl={6} style={{ marginBottom: 10 }}>
                            <div className={classes.root}>
                                <Button
                                    variant="contained"
                                    disabled={include.length < 1 || data.length < 1}
                                    target="_blank"
                                    href={`/report/advancedsearch?${list}`}
                                    className={classes.mr}>
                                    <ExcelIcon />
                                    &nbsp;&nbsp;Export to Excel
                                </Button>
                                <Button
                                    variant="contained"
                                    disabled={include.length < 1 || data.length < 1}
                                    target="_blank"
                                    href={`/export/advanced/shapefile?${shape_list}`}>
                                    <ShapeIcon />
                                    &nbsp;&nbsp;Export to Shapefile
                                </Button>
                            </div>
                        </Grid>
                        <Grid item xs={12} style={{ overflowX: "auto" }}>
                            <Table>
                                <EnhancedTableHead
                                    columnData={includedColumns}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={(e) =>
                                        this.handleRequestSort(typeof e === "string" ? e : e.target.textContent)
                                    }
                                />
                                <TableBody>
                                    {(rowsPerPage > 0
                                        ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : data
                                    ).map((x) => (
                                        <TableRow hover onClick={(e) => this.goActivity(x)} key={x.id}>
                                            {includedColumns.map((z) => (
                                                <CustomTableCell key={x.id + "_" + z.id + "_cell"}>
                                                    {this.display(x, z)}
                                                </CustomTableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                    {data.length === 0 && (
                                        <CustomTableCell
                                            colSpan={includedColumns.length}
                                            className={classes.centerAlign}>
                                            No Records
                                        </CustomTableCell>
                                    )}
                                </TableBody>
                            </Table>
                        </Grid>
                        <Grid item xs={12}>
                            <Table>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            colSpan={includedColumns.length}
                                            count={data.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onChangePage={this.handleChangePage}
                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActionsWrapped}
                                            rowsPerPageOptions={[100, { label: "All", value: -1 }]}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </Grid>
                    </Grid>
                </div>
                <SnackBarError
                    open={noResults}
                    close={(e) => this.setState({ noResults: false })}
                    customMessage="No records match your query"
                />
                <SnackBarError
                    open={dateError}
                    close={(e) => this.setState({ dateError: false })}
                    customMessage={dateMessage}
                />
            </AppContainer>
        );
    }
}

ActivitySearch = connect(
    (state, ownProps) => ({
        authState: state.auth,
        systems: getDrainageSystem(state, ownProps),
        commissioners: getCommissioners(state, ownProps),
        settings: getMapSetting(state, ownProps),
        users: getUsers(state, ownProps),
    }),
    {
        ...authActions,
    }
)(ActivitySearch);

export default withStyles(styles)(withRouter(ActivitySearch));
